import { Service } from "typedi";
import { RouteConfig } from "vue-router";
import IBaseModule from "./contracts/modules/base-module.interface";
import auth from '@/modules/common/stores/auth.store'
import register from '@/modules/common/stores/register.store'
import settings from '@/modules/common/stores/settings.store'
import promocodes from '@/modules/common/stores/promocodes.store'
import payment from '@/modules/common/stores/payment.store'
import formbuilder from '@/modules/common/stores/formbuilder.store'
import media from '@/modules/common/stores/media.store'
import design from '@/modules/common/stores/design.store'
import event from '@/modules/common/stores/event.store'
import options from '@/modules/common/stores/options.store'
import common from '@/modules/common/stores/common.store'
import promote from '@/modules/common/stores/promote.store'

@Service()
export default class CommonModule implements IBaseModule {
    store(): object {
        return {
            auth,
            register,
            settings,
            promocodes,
            payment,
            formbuilder,
            media,
            design,
            event,
            options,
            common,
            promote
        }
    }
    routes(): RouteConfig[] {
        return []
    }
}
