import Container, { Service } from "typedi";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPatchParameters from "../contracts/http-client/http-client-patch-parameters.interface";
import IHttpClientPostParameters from "../contracts/http-client/http-client-post-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class EventService {

    public async mcProStart (event_identifier: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/mcpro-start`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).post(httpParams,event_identifier)
        return response
    }

    public async mcProReturn (event_identifier: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/mcpro-return`,
            requireToken: true,
            payload: { url: window.location.href }
        }
        const response: any = await Container.get(HttpClient).post(httpParams,event_identifier)
        return response
    }

    public async mcTradeStart (event_identifier: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/mctrade-start`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).post(httpParams,event_identifier)
        return response
    }

    public async mcTradeReturn (event_identifier: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/mctrade-return`,
            requireToken: true,
            payload: { url: window.location.href }
        }
        const response: any = await Container.get(HttpClient).post(httpParams,event_identifier)
        return response
    }

    public async getEvent (event_identifier: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams,event_identifier)
        return response
    }

    public async sendAnalytics (event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/analytics`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams,event_identifier)
        return response
    }

    public async sendReceipt(event_identifier: string, participant_token: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/email-receipt?auth_token=${participant_token}`,
            requireToken: true,
            payload: {}
        }
        await Container.get(HttpClient).post(httpParams, event_identifier)
    }

    public async receipt(event_identifier: any, pagenum: any) {
        const httpParams: IHttpClientPatchParameters = {
            url: `/event/${event_identifier}/participant/me/registration-form/${pagenum}`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).patch(httpParams,event_identifier)
        return response
    }
}
