import Vue from 'vue'
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate'
import { required, email, confirmed, min_value, max_value } from 'vee-validate/dist/rules'

extend('required', {
	...required,
	message: 'This field is required',
})
extend('email', email)
extend('password', {
	validate: (value, other ) => value === other,
	message: 'The password confirmation does not match',
	params: [{ name: 'other', isTarget: true }],
})
extend('confirmed', {
	...confirmed,
	message: 'These fields do not match',
})
extend('min_value', {
	...min_value,
	message: 'This field must be greater than or equal to {min}'
});
extend('max_value', {
	...max_value,
	message: 'This field must be less than or equal to {max}'
});
extend('zip_code', {
  validate: value => {
    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
  },
  message: 'This field must be a valid ZIP code'
});
extend('phone_number', {
  validate: value => {
    return /^\(\d{3}\) \d{3}-\d{4}$/.test(value);
  },
  message: 'This field must be a valid phone number'
});
extend('stripe', {
  validate: value => value,
  message: 'The {_field_} field is incomplete or invalid.'
})

extend('file_size_lt_2mb', {
	validate (value) {
		return value.size < 2000000
	},
	message: 'This file is too large, please make it less than 2MB'
})

setInteractionMode('eager')

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
