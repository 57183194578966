import { Service } from 'typedi';
import { Vue, Component } from 'vue-property-decorator'
import checkURLAndGo from '@/bootstrap/global/allowlist'

@Service()
@Component({
  name: 'GtrSuper'
})
export default class GtrSuper extends Vue {
    checkURLAndGo = checkURLAndGo

    get isDev() {
      return window.location.href.includes('/dev/')
    }

    setFavicon(favicon: string) {
      const link: HTMLLinkElement  = document.querySelector(`link[rel*='icon']`) || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel  = 'shortcut icon'
      link.href = favicon
      document.getElementsByName('head')[0].appendChild(link)
    }
}
