import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import Loader from '../models/loader.model'

@Module({
	namespaced: true
})
export default class CommonStore extends VuexModule {
	loader: Loader | undefined = {
		size: 64,
		index: 10,
		value: false
	};
	page = 1
	loadingStripe = false

	getLoader(): Loader | undefined {
		return this.loader
	}

	@Mutation
	SET_LOADER(payload: Loader) {
		this.loader = payload
	}

	@Mutation
	SET_PAGE (payload: any) {
		this.page = payload
	}

	@Mutation
	SET_LOADING_STRIPE (payload: any) {
		this.loadingStripe = payload
	}

	@Action
	showLoader(payload: Loader) {
		this.context.commit('SET_LOADER', payload)
	}

	@Action
	hideLoader() {
		this.context.commit('SET_LOADER', {
			size: 64,
			index: 10,
			value: false
		})
	}
}
