import Vue from 'vue'

Vue.component('error-message', require('@/modules/common/components/error-message/error-message.vue').default)

Vue.component('gtr-loader', require('@/modules/common/components/ui-core/gtr-loader/gtr-loader.vue').default)

Vue.component('gtr-modal', require('@/modules/common/components/ui-core/gtr-modal/gtr-modal.vue').default)

Vue.component('v-style', {
	render: function(createElement) {
		return createElement('style', this.$slots.default)
	}
})

Vue.component('v-script', {
	render: function(createElement) {
		return createElement('script', this.$slots.default)
	}
})