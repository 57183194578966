import Container, { Service } from "typedi";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPostParameters from "../contracts/http-client/http-client-post-parameters.interface";
import IHttpClientPutParameters from "../contracts/http-client/http-client-put-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class MediaService {
    public async getMedia(event_identifier: string) {        
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/media`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async uploadMedia (event_identifier: string, fileData: any) {
        const vaporData = {
			bucket: '',
			content_type: fileData.type,
			expires: '',
			file_name: fileData.name,
        }
        
        let signedResponse: any = {}
        let headers: any = {}
    
        // #region  Vapor Request
        const httpParamsVapor: IHttpClientPostParameters = {
          url: `/event/${event_identifier}/participant/me/vapor/signed-storage-url`,
          requireToken: true,
          payload: vaporData
        }
        const responseVapor: any = await Container.get(HttpClient).post(httpParamsVapor, event_identifier)
        signedResponse = responseVapor.data
        signedResponse.file_name = fileData.name
        headers = responseVapor.data.headers
        delete headers.Host
        // #endregion

        // #region File Request
        const httpParamsFile: IHttpClientPutParameters = {
            url: signedResponse.url,
            requireToken: false,
            payload: fileData,
            config: {
                headers
            }
        }
        const responseFile = await Container.get(HttpClient).put(httpParamsFile, event_identifier)
        // #endregion

        
        return {
            signedResponse,
            responseVapor,
            responseFile,            
        }
    }
}