import { Service } from 'typedi'
import Vue from 'vue'

@Service()
export default class Notification {
  public info (message: string) {
    Vue.$toast.info(message)
  }

  public success (message: string) {
    Vue.$toast.success(message)
  }

  public warning (message: string) {
    Vue.$toast.warning(message)
  }

  public error (message: string) {
    Vue.$toast.error(message)
  }

  public default (message: string) {
    Vue.$toast.default(message)
  }
}
