import Container from 'typedi'
import { VuexModule, Module,Action, Mutation } from 'vuex-module-decorators'
import SettingsService from '../services/settings.service'

@Module({
    namespaced: true
})
export default class SettingStore extends VuexModule {
    settings: any [] = []

    @Mutation
    SET_SETTINGS (payload: any) {
        this.settings = payload
    }

    @Action({ rawError: true })
    async getSettings (payload: any) {
        const { event_identifier }  = payload
        const response = await Container.get(SettingsService).getSettings(event_identifier)
        this.context.commit('SET_SETTINGS', response.data)
        return response
    }
}
