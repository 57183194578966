import IHttpClientGetParameters from "@/modules/common/contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPatchParameters from "@/modules/common/contracts/http-client/http-client-patch-parameters.interface";
import IHttpClientPostParameters from "@/modules/common/contracts/http-client/http-client-post-parameters.interface";
import HttpClient from "@/modules/common/services/http-client.service";
import { Service, Container } from "typedi";

@Service()
export default class LeadsService {
  public async getLeadsOrderForm (event_identifier: string, page_number: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_identifier}/participant/me/registration-form/${page_number}?form=lr_orders`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
    return response
  }

  public async getLeadsOrderFees(event_identifier: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_identifier}/participant/me/fees?form=lr_orders`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
    return response
  }

  public async submitLeadsOrderForm (event_identifier: string, page_number: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_identifier}/participant/me/registration-form/${page_number}?form=lr_orders`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams, event_identifier)
    return response
  }

  public async submitLeadsOrderPayment (event_identifier: string, widget_id: string, transaction_type: string, data: any) {
    transaction_type = transaction_type ?? 'charge'
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_identifier}/participant/me/transaction/processor/${widget_id}/${transaction_type}?form=lr_orders`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
    return response
  }

  public async receipt(event_identifier: string, page_number: string) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_identifier}/participant/me/registration-form/${page_number}?form=lr_orders`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).patch(httpParams, event_identifier)
    return response
  }
}
