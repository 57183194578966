import { Service } from "typedi";
import { RouteConfig } from "vue-router";
import IBaseModule from "../common/contracts/modules/base-module.interface";
import leads from '@/modules/leads/stores/leads.store'
import leadsRoutes from './routes/leads.router'

@Service()
export default class LeadsModule implements IBaseModule {
    store(): object {
        return {
            leads
        }
    }
    routes(): RouteConfig[] {
        return [
            ...leadsRoutes
        ]
    }
}
