// import GtrStorage from '@/modules/common/services/storage.service'
// import Container from 'typedi'
// import Vue from 'vue'
import { RouteConfig } from 'vue-router'

// Vue.use(Router)

// TODO: Revisit this for future refactor.

const surveyRoutes: Array<RouteConfig> = [
    {
        path: '/:event_identifier/surveys',
        alias: '/dev/:event_identifier',
        name: 'surveys.login',
        meta: {
            title: 'Login',
            layout: 'enduser',
            requireAuth: false
        },
        component: () => import('@/modules/survey/views/login/login.vue')
    }, {
        path: '/:event_identifier/surveys/loginsurveys/:login_key/:pagenum',
        alias: '/dev/:event_identifier/surveys/loginsurveys/:login_key/:pagenum',
        name: 'registration.edit-registration',
        meta: {
            title: 'Surveys',
            layout: 'enduser',
            requireAuth: false
        },
        component: () => import('@/modules/survey/views/index/index.vue')
    }, {
        path: '/:event_identifier/surveys/dashboard',
        alias: '/dev/:event_identifier/surveys',
        name: 'surveys.index',
        meta: {
            title: 'Surveys',
            layout: 'enduser',
            requireAuth: true
        },
        component: () => import('@/modules/survey/views/index/index.vue')
    }, {
        path: '/:event_identifier/survey/:session_uuid',
        alias: '/dev/:event_identifier/survey/:session_uuid',
        name: 'surveys.session',
        meta: {
            title: 'Session',
            layout: 'enduser',
            requireAuth: true
        },
        component: () => import('@/modules/survey/views/session/session.vue')
    }, {
        path: '/:event_identifier/survey/:session_uuid/finish',
        alias: '/dev/:event_identifier/survey/:session_uuid/finish',
        name: 'surveys.session.finish',
        meta: {
            title: 'Finish',
            layout: 'enduser',
            requireAuth: true
        },
        component: () => import('@/modules/survey/views/finish/finish.vue')
    },
]



// const router = new Router({
//     mode: 'history',
//     routes: [
//         {
//             path: '/:event_identifier',
//             alias: '/dev/:event_identifier',
//             name: 'surveys.login',
//             meta: {
//                 title: 'Login',
//                 layout: 'enduser',
//                 requireAuth: false
//             },
//             component: () => import('@/modules/survey/views/login/login.vue')
//         },
//         {
//             path: '/:event_identifier/surveys',
//             alias: '/dev/:event_identifier/surveys',
//             name: 'surveys.index',
//             meta: {
//                 title: 'Surveys',
//                 layout: 'enduser',
//                 requireAuth: true
//             },
//             component: () => import('@/modules/survey/views/index/index.vue')
//         },
//         {
//             path: '/:event_identifier/survey/:session_uuid',
//             alias: '/dev/:event_identifier/survey/:session_uuid',
//             name: 'surveys.session',
//             meta: {
//                 title: 'Session',
//                 layout: 'enduser',
//                 requireAuth: true
//             },
//             component: () => import('@/modules/survey/views/session/session.vue')
//         },
//         {
//             path: '/:event_identifier/survey/:session_uuid/finish',
//             alias: '/dev/:event_identifier/survey/:session_uuid/finish',
//             name: 'surveys.session.finish',
//             meta: {
//                 title: 'Finish',
//                 layout: 'enduser',
//                 requireAuth: true
//             },
//             component: () => import('@/modules/survey/views/finish/finish.vue')
//         },
//     ]
// })

// router.beforeEach((to: Route,from: Route, next: NavigationGuardNext) => {
//     if (to.meta.title) {
//         document.title = `${process.env.VUE_APP_NAME} | ${to.meta.title}`
//     } else {
//         document.title = `${process.env.VUE_APP_NAME}`
//     }
//     const isProtecteRoute  = to.matched.some(value => value.meta.requireAuth)
//     const event_identifier = to.params.event_identifier
//     if (event_identifier) {
//         const token = Container.get(GtrStorage).getItem(`${event_identifier}_registration_access_token`)
//         if(isProtecteRoute) {
//             if (token) {
//                 next()
//             }else{
//                 next({ name: 'surveys.login', params: { 'event_identifier': event_identifier } })
//             }
//         }else{
//             next()
//         }
//     }
//     next()
// })

export default surveyRoutes
