import Container from "typedi";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import MediaService from "../services/media.service";

@Module({
    namespaced: true
})
export default class MediaStore extends VuexModule {
    media: any[] = []

    @Mutation
    SET_MEDIA(payload: any) {
		this.media = payload
    }
    
    @Action({ rawError: true })
    public async getMedia (payload: any) {
        const { event_identifier } = payload
        const response = await Container.get(MediaService).getMedia(event_identifier)
        this.context.commit('SET_MEDIA', response.data)
        return response
    }

    @Action({ rawError: true })
    public async uploadMedia (payload: any) {
        const { event_identifier, fileData } = payload
        const response = await Container.get(MediaService).uploadMedia(event_identifier, fileData)
        this.context.dispatch('getMedia', event_identifier)
        return response
    }
}
