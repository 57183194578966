import Container, { Service } from "typedi";
import IHttpClientPatchParameters from "../contracts/http-client/http-client-patch-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class PromoCodesService {
    public async applyPromoCode (event_identifier: string, participant_uuid: string, data: any) {
        delete data.event_identifier
        delete data.participant_uuid
        const httpParams: IHttpClientPatchParameters = {
            url: `/event/${event_identifier}/participant/${participant_uuid}/promo-code`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).patch(httpParams, event_identifier)
        return response
    }
}
