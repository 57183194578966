import Container, { Service } from "typedi";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPatchParameters from "../contracts/http-client/http-client-patch-parameters.interface";
import IHttpClientPostParameters from "../contracts/http-client/http-client-post-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class FormBuilderService {
    public async getForm (event_identifier: string, page_number: string, languageAddOn: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/participant/me/registration-form/${page_number}${languageAddOn}`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async submitPayment (event_identifier: string, widget: string, transaction_type: string, data: any) {
        transaction_type = transaction_type ?? 'charge'
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/transaction/processor/${widget}/${transaction_type}`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async submitForm (event_identifier: string, page_number: string, data: any) {
        const httpParams: IHttpClientPatchParameters = {
            url: `/event/${event_identifier}/participant/me/registration-form/${page_number}`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).patch(httpParams, event_identifier)
        return response
    }

    public async cancelReg (event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/cancel`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async transferReg (event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/transfer`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }
}
