import Container from 'typedi'
import {VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import LeadsService from '../services/leads.service'

@Module({
    namespaced: true
})
export default class LeadsStore extends VuexModule {
  form: any = []
  lr_order_fees: any = {}
  finishedPayment = false

  @Mutation
  SET_FORM(payload: any) {
    this.form = payload
  }

  @Mutation
  SET_LR_ORDER_FEES(payload: any) {
    this.lr_order_fees = payload
  }

  @Mutation
  SET_FINISHED_PAYMENT(payload: any) {
    this.finishedPayment = payload
  }

  @Action({ rawError: true })
  async getLeadsOrderForm (payload: any) {
    const { event_identifier, page_number } = payload
    const response = await Container.get(LeadsService).getLeadsOrderForm(event_identifier, page_number)
    this.context.commit('SET_FORM', response.data)
    return response
  }

  @Action({ rawError: true })
  async getLeadsOrderFees (payload: any) {
    const { event_identifier, participant_uuid } = payload
    const response = await Container.get(LeadsService).getLeadsOrderFees(event_identifier, participant_uuid)
    this.context.commit('SET_LR_ORDER_FEES', response.data)
    return response
  }

  @Action({ rawError: true })
  async submitLeadsOrderForm (payload: any) {
    const { event_identifier, page_number } = payload
    delete payload.event_identifier
    delete payload.page_number
    delete payload.currentLanguage
    delete payload.last_viewable_reg_page
    delete payload.goToReceiptPage
    const response = await Container.get(LeadsService).submitLeadsOrderForm(event_identifier, page_number, payload)
    const next_page = response.data.next_page
    return response
  }

  @Action({ rawError: true })
  async submitLeadsOrderPayment (payload: any) {
    const { event_identifier, widget_id, transaction_type } = payload
    delete payload.event_identifier
    delete payload.page_number
    delete payload.currentLanguage
    const response = await Container.get(LeadsService).submitLeadsOrderPayment(event_identifier, widget_id, transaction_type, payload)
    this.context.commit('SET_FINISHED_PAYMENT', true)
    return response
  }

  @Action({ rawError: true })
  async receipt(payload: any) {
    const { event_identifier, pagenum } = payload
    this.context.commit('SET_FINISHED_PAYMENT', false)
    const response = await Container.get(LeadsService).receipt(event_identifier, pagenum)
    return response
  }
}
