import Container, { Service } from "typedi";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class SettingsService {
    public async getSettings(event_identifier: any) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/content/settings/default`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }
}