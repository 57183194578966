const _navigator: any = window.navigator
_navigator.os = (function() {
	const userAgent = window.navigator.userAgent,
		platform = window.navigator.platform,
		macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
		windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
		iosPlatforms = ['iPhone', 'iPad', 'iPod']
	let os: any = null

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'Mac OS'
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS'
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows'
	} else if (/Android/.test(userAgent)) {
		os = 'Android'
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux'
	}

	return os
})()

_navigator.browserSpecs = (function() {
	const ua = navigator.userAgent
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    let tem
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || []
		return { name: 'IE', version: tem[1] || '' }
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
		if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] }
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
	if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
	return { name: M[0], version: M[1] }
})()
