import Container, { Service } from "typedi";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPatchParameters from "../contracts/http-client/http-client-patch-parameters.interface";
import IHttpClientPostParameters from "../contracts/http-client/http-client-post-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class AuthService {
    public async updatePassword (event_identifier: string, password_token: string, data: any) {
        const httpParams: IHttpClientPatchParameters = {
            url: `/event/${event_identifier}/participant/password/token/${password_token}`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).patch(httpParams, event_identifier)
        return response
    }

    public async emailResetPassword (event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/password/token`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async emailLoginDetails (event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/login-details-email`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async login (event_identifier: string, query_string: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/session${query_string}`,
            requireToken: false,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async registration(event_identifier: any, query_string: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/registration${query_string}`,
            requireToken: false,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async requestInvite (event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/registration-form/invite-request`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async getSession (event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/session`,
            requireToken: true,
            payload: data
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async getParticipant (event_identifier: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/participant/me`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }
}
