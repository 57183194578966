import Container, { Service } from "typedi";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPostParameters from "../contracts/http-client/http-client-post-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class PaymentService {

    public async getPaymentProperties (event_identifier: string, widget: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/participant/me/transaction/processor/${widget}/properties`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async startPayFlowToken(event_identifier: any, widget: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/transaction/processor/${widget}/token`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async startStripeToken(event_identifier: any, widget: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/transaction/processor/${widget}/token`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async startStripeTokenLeads(event_identifier: any, widget: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/transaction/processor/${widget}/token?form=lr_orders`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async getFees(event_identifier: any, participant_uuid: any) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/participant/${participant_uuid}/fees`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    // flagging this for review.
    // TODO: review this.
    public async getGroupFees(event_identifier: string, group_uuid: any) {
        const httpParams:  IHttpClientGetParameters  = {
            url: `/event/${event_identifier}/group/${group_uuid}/fees`,
            requireToken: true,
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async makePayment () {
        // TODO
    }
}
