import Container, { Service } from "typedi";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPostParameters from "../contracts/http-client/http-client-post-parameters.interface";
import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import HttpClient from "./http-client.service";

@Service()
export default class RegisterService {
    public async createGroupRegistration (event_identifier: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/group`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async addToGroupRegistration(event_identifier: string, group_uuid: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/group/${group_uuid}/participant/me`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
        return response
    }

    public async getAllContent (event_identifier: string, reg_type: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/setup/${reg_type ? reg_type : 'default'}`,
            requireToken: false
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async deleteParticipantFromGroup (event_uuid: string, group_uuid: string, participant_uuid: string) {
        const httpParams: IHttpClientDeleteParameters = {
            url: `/event/${event_uuid}/group/${group_uuid}/participant/${participant_uuid}`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).delete(httpParams)
        return response
    }

    public async cancelRegistration(event_identifier: string, token: string) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/participant/me/cancel?auth_token=${token}`,
            requireToken: true
        }
        return await Container.get(HttpClient).post(httpParams, event_identifier)
    }

    public async idpStart(event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/event/${event_identifier}/idp-start`,
            requireToken: true,
            payload: data
        }
        return await Container.get(HttpClient).post(httpParams, event_identifier)
    }

    public async idpReturnData(event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/idp-return`,
            requireToken: true,
            payload: data
        }
        return await Container.get(HttpClient).post(httpParams, event_identifier)
    }


    public async idpChoose(event_identifier: string, data: any) {
        const httpParams: IHttpClientPostParameters = {
            url: `/idp-choose`,
            requireToken: true,
            payload: data
        }
        return await Container.get(HttpClient).post(httpParams, event_identifier)
    }
}
