import CommonModule from '@/modules/common/index.module'
import SurveyModule from '@/modules/survey/index.module'
import LeadsModule from '@/modules/leads/index.module'
import Container from 'typedi'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        ...Container.get(CommonModule).store(),
        ...Container.get(SurveyModule).store(),
        ...Container.get(LeadsModule).store()
    }
})
