import Container from 'typedi'
import {VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import AuthService from '../services/auth.service'
import GtrStorage from '../services/storage.service'
@Module({
    namespaced: true
})
export default class AuthStore extends VuexModule {
    event: any = {}
	viewable_reg_pages: any[] = []
	current_reg_pages: any[] = []
	participant: any = {}
	needAuthField =  false
    requireInvite = false

    @Mutation
    SET_EVENT (payload: any) {
        this.event = payload
    }

    @Mutation
    SET_PARTICIPANT (payload: any) {
        this.participant = payload
    }

    @Mutation
    SET_VIEWABLE_REG_PAGES (payload: any) {
        this.viewable_reg_pages = payload
    }

    @Mutation
    SET_CURRENT_REG_PAGES (payload: any) {
        this.current_reg_pages = payload
    }

    @Mutation
    SET_NEED_AUTH_FIELD (payload: any) {
        this.needAuthField = payload
    }

    @Mutation
    SET_REQUIRE_INVITE (payload: any) {
        this.requireInvite = payload
    }

    @Action({ rawError: true })
    async updatePassword (payload: any) {
        const { event_identifier,isDev, password_token, data } = payload // eslint-disable-line @typescript-eslint/no-unused-vars
        const response = await Container.get(AuthService).updatePassword(event_identifier, password_token, data)
        // router.push('/' + (isDev ? 'dev/' : '') + event_identifier)
        return response
    }

    @Action({ rawError: true })
    async emailResetPassword (payload: any) {
        const { event_identifier, data } = payload
        const response = await Container.get(AuthService).emailResetPassword(event_identifier, data)
        return response
    }

    @Action({ rawError: true })
    async emailLoginDetails (payload: any) {
        const { event_identifier, data } = payload
        const response = await Container.get(AuthService).emailLoginDetails(event_identifier, data)
        return response
    }

    @Action({ rawError: true })
    async login (payload: any) {
        const { event_identifier, pagenum, isDev, form, data } = payload // eslint-disable-line @typescript-eslint/no-unused-vars
        const query_params: any = []
        const registration_type_query_param = data.registrationTypeData ? `registration_type=${data.registrationTypeData.option_uuid}` : ''
        if (registration_type_query_param) {
            query_params.push(registration_type_query_param)
        }
        if (form) {
            query_params.push('form=' + form)
        }
        const query_string = query_params.length ? '?' + query_params.join('&') : ''
        const response = await Container.get(AuthService).login(event_identifier, query_string, data)
        const access_token = response.data.access_token
        if (Container.get(GtrStorage).getItem(`${event_identifier}_registration_access_token`) && Container.get(GtrStorage).getItem(`${event_identifier}_group_uuid`) && !Container.get(GtrStorage).getItem(`${event_identifier}_group_parent_access_token`)) {
            /**
             * First registration's access token should now be the group parent token, to be used in group calls
             */
            Container.get(GtrStorage).setItem(`${event_identifier}_group_parent_access_token`, Container.get(GtrStorage).getItem(`${event_identifier}_registration_access_token`))
        }
        /**
         * After saving the group parent access token or just doing a regular single registration login, set the access token in local storage and for axios
         */
        Container.get(GtrStorage).setItem(`${event_identifier}_registration_access_token`, access_token)
        this.context.commit('SET_CURRENT_REG_PAGES', response.data.current_reg_pages)
        this.context.commit('SET_VIEWABLE_REG_PAGES', response.data.viewable_reg_pages)
        const group_uuid = Container.get(GtrStorage).getItem(`${event_identifier}_group_uuid`)
        if (group_uuid && response.data.participant.participant_group_uuid == null) {
            const data = {
                event_identifier,
                group_uuid,
            }
            this.context.dispatch('register/addToGroupRegistration', data, { root: true })
        }
        return response
        //router.push('/' + (isDev ? 'dev/' : '') + event_identifier + '/register/' + response.data.viewable_reg_pages.shift())
    }

    @Action({ rawError: true })

    async registration(payload: any) {
        const { event_identifier, form, data } = payload
        const query_params: any = []
        const registration_type_query_param = data.registrationTypeData ? `registration_type=${data.registrationTypeData.option_uuid}` : ''
        if (registration_type_query_param) {
            query_params.push(registration_type_query_param)
        }
        if (form) {
            query_params.push('form=' + form)
        }
        const query_string = query_params.length ? '?' + query_params.join('&') : ''
        const response = await Container.get(AuthService).registration(event_identifier, query_string, data)
        return response
    }

    @Action({ rawError: true })
    async requestInvite (payload: any) {
        const  { event_identifier, data } = payload
        const response = await Container.get(AuthService).requestInvite(event_identifier, data)
        return response
    }

    @Action({ rawError: true })
    async getSession (payload: any) {
        const { event_identifier,data } = payload
        const response = await Container.get(AuthService).getSession(event_identifier, data)
        return response
    }

    @Action({ rawError: true })
    async getParticipant (payload: any) {
        const { event_identifier } = payload
        const response = await Container.get(AuthService).getParticipant(event_identifier)
        this.context.commit('SET_PARTICIPANT', response.data)
        return response
    }
}
