const translateError = (error: any) => {
  if (error.status === 401 && error.config && error.config.url !== 'auth/session') {
    return null
  }
  if (error.message === 'Request aborted') {
    return null;
  }
  if (error.data && error.data.errors) {
    let message = ''
    for (const index in error.data.errors) {
      message += error.data.errors[index].pop()
    }
    return message
  } else if (error.data && error.data.error_message) {
    return error.data.error_message
  } else if (error.data && error.data.message) {
    return error.data.message
  } else if (error.response && error.response.data && error.response.data.error_message) {
    return error.response.data.error_message
  }
  return 'There was an unexpected error. Please contact support.'
}
export default translateError
