import Container from 'typedi'
import { VuexModule, Module, Action } from 'vuex-module-decorators'
import PromoteService from '../services/promote.service'

@Module({
  namespaced: true
})
export default class PromoteStore extends VuexModule {

  @Action({ rawError: true })
  async addToReferralCodeClickCount (payload: any) {
    const { event_identifier, referral_code } = payload
    const response = await Container.get(PromoteService).addToReferralCodeClickCount(event_identifier, referral_code, payload)
    return response
  }
}
