import { VuexModule, Module, Mutation } from 'vuex-module-decorators'

@Module({
    namespaced: true
})
export default class DesignStore extends VuexModule {
    design: any[] = []

    @Mutation
    SET_DESIGN (payload: any) {
        this.design = payload
    }
}