import Container from 'typedi'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import RegisterService from '../services/register.service'
import GtrStorage from '../services/storage.service'

@Module({
    namespaced: true
})
export default class RegisterStore extends VuexModule {
    allContent: any[] = []

    @Mutation
    SET_ALL_CONTENT (payload: any) {
        this.allContent = payload
    }

    @Action({ rawError: true })
    async createGroupRegistration(payload: any) {
        const { event_identifier } = payload
        const response = await Container.get(RegisterService).createGroupRegistration(event_identifier)
        Container.get(GtrStorage).setItem(`${event_identifier}_group_uuid`, response.data.uuid)
        const data: any = {
            event_identifier,
            group_uuid: response.data.uuid,
        }
        await this.context.dispatch('addToGroupRegistration', data)
        return response
    }

    @Action({ rawError: true })
    async addToGroupRegistration (payload: any) {
        const  { event_identifier, group_uuid} = payload
        return await Container.get(RegisterService).addToGroupRegistration(event_identifier, group_uuid)
    }

    @Action({ rawError: true })
    public async getAllContent (payload: any) {
        const  { event_identifier, reg_type } = payload
        const response = await Container.get(RegisterService).getAllContent(event_identifier, reg_type)
        this.context.commit('SET_ALL_CONTENT', response)
        return response
    }

    @Action({ rawError: true })
    async deleteParticipantFromGroup (payload: any) {
        const { event_uuid, group_uuid, participant_uuid } = payload
        return await Container.get(RegisterService).deleteParticipantFromGroup(event_uuid, group_uuid, participant_uuid)
    }

    @Action({ rawError: true })
    public async cancelRegistration(payload: { event_identifier: string; token: string }) {
        const { event_identifier, token } = payload
        return await Container.get(RegisterService).cancelRegistration(event_identifier, token)
    }

    @Action({ rawError: true })
    public async idpStart(payload: { event_identifier: string; data: any }) {
        const { event_identifier, data } = payload
        return await Container.get(RegisterService).idpStart(event_identifier, data)
    }

    @Action({ rawError: true })
    public async idpReturnData(payload: { event_identifier: string; data: any }) {
        const { event_identifier, data } = payload
        return await Container.get(RegisterService).idpReturnData(event_identifier, data)
    }


    @Action({ rawError: true })
    public async idpChoose(payload: { event_identifier: string; data: any }) {
        const { event_identifier, data } = payload
        return await Container.get(RegisterService).idpChoose(event_identifier, data)
    }
}
