import Container, { Service } from "typedi";
import IHttpClientDeleteParameters from "../contracts/http-client/http-client-delete-parameters.interface";
import IHttpClientGetParameters from "../contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPatchParameters from "../contracts/http-client/http-client-patch-parameters.interface";
import IHttpClientPutParameters from "../contracts/http-client/http-client-put-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class OptionsService {
    public async getOptionGroups(event_identifier: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_identifier}/option-groups`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async bulkOptionsUpdate (event_identifier: string, selections: object, lr_orders: boolean) {
        let urlExtra = ''
        if (lr_orders) {
            urlExtra = '?form=lr_orders'
        }
        const httpParams: IHttpClientPutParameters = {
            url: `/event/${event_identifier}/participant/me/options${urlExtra}`,
            requireToken: true,
            payload: { selections }
        }
        const response: any = await Container.get(HttpClient).put(httpParams, event_identifier)
        return response
    }

    public async optionChange (option_group_uuid: string, option_uuid: string, event_identifier: string, type: string, data: any) {
        delete data.option_group_uuid
        delete data.option_uuid
        delete data.event_identifier
        let addOnQuery = '?removeOtherSelections=true'
        if (type === 'checkbox') {
          addOnQuery = ''
        }
        const httpParams: IHttpClientPatchParameters = {
            url: `event/${event_identifier}/participant/me/option/${option_group_uuid}/${option_uuid}${addOnQuery}`,
            requireToken: true,
            payload:data
        }
        const response: any = await Container.get(HttpClient).patch(httpParams, event_identifier)
        return response
    }

    public async optionDelete ( option_group_uuid: string, option_uuid: string, event_identifier: string) {
        const httpParams: IHttpClientDeleteParameters = {
            url: `/event/${event_identifier}/participant/me/option/${option_group_uuid}/${option_uuid}`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).delete(httpParams, event_identifier)
        return response
    }
}
