import Container from 'typedi'
import {VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import SurveysService from '../services/surveys.service'

@Module({
    namespaced: true
})
export default class SurveysStore extends VuexModule {
    surveys: any[] = []
    survey: any
    session: any
    certificates: any[] = []
    sessionCredits: any = {}

    @Mutation
    SET_SURVEYS(payload: any) {
        this.surveys = payload
    }

    @Mutation
    SET_SESSION_CREDITS(payload: any): void {
        this.sessionCredits = payload
    }

    @Mutation
    SET_SURVEY(payload: any) {
        this.survey = payload
    }

    @Mutation
    SET_SESSION (payload: any) {
        this.session = payload
    }

    @Mutation
    SET_CERTIFICATES (payload: any) {
        this.certificates = payload
    }

    @Action({ rawError: true })
    async getSurveys(payload: any) {
        const { event_uuid, event_identifier } = payload
        const response: any = await Container.get(SurveysService).getSurveys(event_uuid, event_identifier)
        const { data: surveys = {} } = response
        if ('general' in surveys) {
            const { data: generalSurvey = {} } = await Container.get(SurveysService).getSession(event_uuid, 'general', event_identifier)
            const { pageData: {
                fields = []
            } = {} } = generalSurvey
            surveys.general[0].fields = fields
        }
        this.context.commit('SET_SURVEYS', surveys)
        return response
    }

    @Action({ rawError: true })
    async getSession(payload: any) {
        const { event_uuid, session_uuid, event_identifier} = payload
        const response: any = await Container.get(SurveysService).getSession(event_uuid, session_uuid, event_identifier)
        this.context.commit('SET_SESSION', response.data)
        return response
    }

    @Action({ rawError: true})
    async submitSurveyAnswer(payload: any) {
        const { event_uuid, session_uuid, body } = payload
        const response = await Container.get(SurveysService).submitSurveyAnswer(event_uuid, session_uuid, body)
        return response
    }

    @Action({ rawError: true })
    async getSurveyCredits({ event_uuid }: any): Promise<any> {
        const response = await Container.get(SurveysService).getSurveyCredits(event_uuid)
        this.context.commit('SET_SESSION_CREDITS', response.data)
        return response
    }

    @Action({ rawError: true })
    async getCertificates(payload: any) {
        const { event_uuid } = payload
        const response: any = await Container.get(SurveysService).getCertificates(event_uuid)
        this.context.commit('SET_CERTIFICATES', response.data)
        return response
    }

    @Action({ rawError: true })
    async getCertificateExport(payload: any) {
        const { event_uuid, certificate_uuid } = payload
        const response: any = await Container.get(SurveysService).getCertificateExport(event_uuid, certificate_uuid)
        return response
    }
}
