import { Service } from "typedi";
import { RouteConfig } from "vue-router";
import IBaseModule from "../common/contracts/modules/base-module.interface";
import surveys from '@/modules/survey/stores/surveys.store'
import surveyRoutes from './routes/survey.router'

@Service()
export default class SurveyModule implements IBaseModule {
    store(): object {
        return {
            surveys
        }
    }
    routes(): RouteConfig[] {
        return [
            ...surveyRoutes
        ]
    }
}
