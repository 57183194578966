import IHttpClientGetParameters from "@/modules/common/contracts/http-client/http-client-get-parameters.interface";
import IHttpClientPostParameters from "@/modules/common/contracts/http-client/http-client-post-parameters.interface";
import HttpClient from "@/modules/common/services/http-client.service";
import { Service, Container } from "typedi";

@Service()
export default class SurveysService {

    public async getSurveys(event_uuid: string, event_identifier: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_uuid}/participant/me/surveys`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async getSession(event_uuid: any, session_uuid: any, event_identifier: string) {
        let url = ''
        if(session_uuid === 'general' || session_uuid === 'keynote') {
            url = `/event/${event_uuid}/participant/me/survey-form/${session_uuid}`
        } else {
            url = `/event/${event_uuid}/participant/me/survey-form/session/${session_uuid}`
        }
        const httpParams: IHttpClientGetParameters = {
            url: url,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams, event_identifier)
        return response
    }

    public async submitSurveyAnswer(event_uuid: string, session_uuid: string, body: any) {
        let url = ''
        if(session_uuid === 'general' || session_uuid === 'keynote') {
            url  = `/event/${event_uuid}/participant/me/survey-form/${session_uuid}`
        } else {
            url = `/event/${event_uuid}/participant/me/survey-form/session/${session_uuid}`
        }
        const httpParams: IHttpClientPostParameters = {
            url: url,
            requireToken: true,
            payload: body
        }
        const response: any = await Container.get(HttpClient).post(httpParams)
        return response
    }

    public async getSurveyCredits(event_uuid: string): Promise<any> {
        const httpParams: IHttpClientGetParameters = {
            url: `event/${event_uuid}/participant/me/credits`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams)
        return response
    }

    public async getCertificates(event_uuid: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_uuid}/participant/me/survey-certificates`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams)
        return response
    }

    public async getCertificateExport(event_uuid: string, certificate_uuid: string) {
        const httpParams: IHttpClientGetParameters = {
            url: `/event/${event_uuid}/participant/me/survey-certificates/export/${certificate_uuid}`,
            requireToken: true
        }
        const response: any = await Container.get(HttpClient).get(httpParams)
        return response
    }
}
