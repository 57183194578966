import Vue from 'vue'
import Router from 'vue-router'
import { RouteConfig } from 'vue-router'
import store from '@/bootstrap/store'
import vuetify from '@/plugins/vuetify';
import GtrPortalApplication from '@/bootstrap/application/application.vue'
import '@/assets/styles/common.css'
import register from '@/modules/registration/routes/registration.router'
import surveys  from '@/modules/survey/routes/survey.router'
import '@stripe/stripe-js';

Vue.config.productionTip = false
Vue.config.devtools = true

const host = window.location.host;
const parts = host.split('.');
const domainLength = 3;

const _router = () => {
  let routes: Router;
  let registerSubDomain = '', evalSubDomain = '';
  const env = process.env.VUE_APP_ENV
  if (env === 'dev') {
    registerSubDomain = 'qa-register'
    evalSubDomain = 'qa-evals'
  }else if (env === 'uat') {
    registerSubDomain = 'uat-register'
    evalSubDomain = 'uat-evals'
  }else if (env === 'staging') {
    registerSubDomain = 'staging-register'
    evalSubDomain = 'staging-evals'
  }else if (env === 'production') {
    registerSubDomain = 'register'
    evalSubDomain = 'evals'
  }
  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    routes = register;
  } else if (parts[0] === registerSubDomain) {
    routes = register
  } else if (parts[0] === evalSubDomain) {
    // routes = surveys;
    routes = register
  } else {
    routes = register;
  }
  return routes;
};

const routeToUse = _router()
export default routeToUse

new Vue({
  router: _router(),
  store,
  vuetify,
  render: h => h(GtrPortalApplication)
}).$mount('#app')
