import Container, { Service } from 'typedi'
import GtrStorage from './storage.service'

@Service()
export default class SecurityContext {
  public context () {
    return Container.get(GtrStorage).getItem('security_context')
  }

  public accessLevel () {
    const context = Container.get(GtrStorage).getItem('security_context')
    if (context) {
      return JSON.parse(context).access_level || []
    }
    return []
  }

  public token (event_identifier?: string) {
    const token = Container.get(GtrStorage).getItem(`${event_identifier}_registration_access_token`)
    return token
  }
}
