import { Component, Vue } from 'vue-property-decorator'
import 'reflect-metadata';
import '@/plugins/index'
import '@/bootstrap/global/filters'
import '@/bootstrap/global/mixins'
import '@/bootstrap/global/naviagator'
import '@/bootstrap/validation/rules'
import '@/bootstrap/global/prototypes'
import '@/bootstrap/global/loader-component'
import GtrEndUserLayout from '@/modules/common/views/layouts/enduser.layout.vue';
import { mapState } from 'vuex';

@Component({
	name: 'GtrPortalApplication',
	computed: mapState('common', ['loader']),
	components: {
		'enduser-layout': GtrEndUserLayout
	}
})
export default class GtrPortalApplication extends Vue {
	//#region Computed Props
	get layout() {
		if (this.$route.meta && this.$route.meta.layout) {
			return `${this.$route.meta.layout}-layout`
		}
		return 'div'
	}
	//#endregion
}
