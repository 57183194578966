import Container, { Service } from "typedi";
import IHttpClientPostParameters from "../contracts/http-client/http-client-post-parameters.interface";
import HttpClient from "./http-client.service";

@Service()
export default class PromoteService {
  public async addToReferralCodeClickCount (event_identifier: string, referral_code: string, data: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_identifier}/referral-code/${referral_code}/usage`,
      requireToken: false,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams, event_identifier)
    return response
  }
}
