import { Service } from 'typedi'

@Service()
export default class GtrStorage {
  public setItem (key: string, item: any): void {
    if (this.storageSupported()) {
      sessionStorage.setItem(key, item)
    } else {
      throw new Error('SessionStorage not supported.')
    }
  }

  public getItem (key: string): any {
    if (this.storageSupported()) {
      return sessionStorage.getItem(key)
    } else {
      throw new Error('SessionStorage not supported.')
    }
  }

  public removeItem (key: string): void {
    if (this.storageSupported()) {
      sessionStorage.removeItem(key)
    } else {
      throw new Error('SessionStorage not supported.')
    }
  }

  public clear (): void {
    if (this.storageSupported()) {
      sessionStorage.clear()
    } else {
      throw new Error('SessionStorage not supported.')
    }
  }

  private storageSupported () {
    return window.sessionStorage !== null && window.sessionStorage !== undefined
  }
}
