// import GtrStorage from '@/modules/common/services/storage.service'
// import Container from 'typedi'
// import Vue from 'vue'
// import Router from 'vue-router'
import { RouteConfig } from 'vue-router'

// Vue.use(Router)

const leadsRoutes: Array<RouteConfig> = [
    {
        path: '/:event_identifier/leads',
        alias: '/dev/:event_identifier/leads',
        name: 'leads.login',
        meta: {
            title: 'Login',
            layout: 'enduser',
            requireAuth: false
        },
        component: () => import('@/modules/leads/views/login/login.vue')
    },
    {
        path: '/:event_identifier/leads/loginregistration/:login_key/:pagenum',
        alias: '/dev/:event_identifier/leads/loginregistration/:login_key/:pagenum',
        name: 'leads.edit-order',
        meta: {
            title: 'Leads Order',
            layout: 'enduser',
            requireAuth: false
        },
        component: () => import('@/modules/leads/views/order/order.vue')
    },
{
        path: '/:event_identifier/leads/order/:pagenum',
        alias: '/dev/:event_identifier/leads/order/:pagenum',
        name: 'leads.order',
        meta: {
            title: 'Leads Order',
            layout: 'enduser',
            requireAuth: false
        },
        component: () => import('@/modules/leads/views/order/order.vue')
    },
    {
        path: '/:event_identifier/leads/orders-closed',
        name: 'leads.orders-closed',
        meta: {
            title: 'Leads Orders Closed',
            layout: 'enduser',
            requireAuth: false
        },
        component: () => import('@/modules/leads/views/orders-closed/orders-closed.vue')
    }
]

export default leadsRoutes
