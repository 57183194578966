import Container from 'typedi'
import { VuexModule, Module, Action } from 'vuex-module-decorators'
import PromoCodesService from '../services/promocodes.service'

@Module({
    namespaced: true
})
export default class PromoCodeStore extends VuexModule {

    @Action({ rawError: true })
    async applyPromoCode (payload: any) {
        const { event_identifier, participant_uuid } = payload
        const response = await Container.get(PromoCodesService).applyPromoCode(event_identifier, participant_uuid, payload)
        return response
    }
}