import Container from 'typedi'
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import EventService from '../services/event.service'
import GtrStorage from '../services/storage.service'

@Module({
    namespaced: true
})
export default class EventStore extends VuexModule {
    event: any = {}
    event_identifier: any = null

    @Mutation
    SET_EVENT (payload: any)  {
        this.event = payload
    }

    @Mutation
    SET_EVENT_IDENTIFIER (payload: any) {
        this.event_identifier = payload
    }

    @Action({ rawError: true })
    async getEvent (payload: any) {
        const { event_identifier } = payload
        const response = await Container.get(EventService).getEvent(event_identifier)
        this.context.commit('SET_EVENT', response.data)
        return response
    }

    @Action({ rawError: true })
    async mcProStart (event_identifier: string) {
        return await Container.get(EventService).mcProStart(event_identifier)
    }

    @Action({ rawError: true })
    async mcProReturn (event_identifier: string) {
        return await Container.get(EventService).mcProReturn(event_identifier)
    }

    @Action({ rawError: true })
    async mcTradeStart (event_identifier: string) {
        return await Container.get(EventService).mcTradeStart(event_identifier)
    }

    @Action({ rawError: true })
    async mcTradeReturn (event_identifier: string) {
        return await Container.get(EventService).mcTradeReturn(event_identifier)
    }
    
    @Action({ rawError: true })
    async sendReceipt (payload: { event_identifier: string; participant_token: string }) {
        const { event_identifier, participant_token } = payload
        await Container.get(EventService).sendReceipt(event_identifier, participant_token)
    }

    @Action({ rawError: true })
    async sendAnalytics (payload: any) {
        const { event_identifier, participant_uuid } = payload
        let unique_id: any = null
		if (Container.get(GtrStorage).getItem(`${event_identifier}_unique_id`)) {
			unique_id = Container.get(GtrStorage).getItem(`${event_identifier}_unique_id`)
		} else {
			unique_id = String(Math.floor(Math.random() * 1000000) + 1)
			Container.get(GtrStorage).setItem(`${event_identifier}_unique_id`, unique_id)
        }
        const navigator: any = window.navigator
        const data: any =  {
            unique_id,
            browser: navigator.browserSpecs.name,
            browser_version: navigator.browserSpecs.name + ' ' + navigator.browserSpecs.version,
            os: navigator.os,
            url: window.location.href,
            action: 'load',
            participant_uuid: participant_uuid,
        }
        const response = await Container.get(EventService).sendAnalytics(event_identifier,data)
        return response
    }

    @Action({ rawError: true })
    async receipt(payload: any) {
        const { event_identifier, pagenum } = payload
        const response = await Container.get(EventService).receipt(event_identifier, pagenum)
        return response
    }
}
