import Container from 'typedi'
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import OptionsService from '../services/options.service'

@Module({
    namespaced: true
})
export default class OptionsStore extends VuexModule {
    option_groups: any[] = []
    participantUpdated = false
    
    @Mutation
    SET_OPTION_GROUPS(payload: any) {
		this.option_groups = payload
    }
    
    @Mutation
	SET_PARTICIPANT_UPDATED(payload: any) {
		this.participantUpdated = payload
    }
    
    @Action({ rawError: true })    
    async getOptionGroups(payload: any) {
        const { event_identifier } = payload
        const response = await Container.get(OptionsService).getOptionGroups(event_identifier)
        this.context.commit('SET_OPTION_GROUPS', response.data)
        return response
    }

    @Action({ rawError: true })    
    async bulkOptionsUpdate (payload: any) {
        const { event_identifier, selections, lr_orders } = payload
        const response = await Container.get(OptionsService).bulkOptionsUpdate(event_identifier, selections, lr_orders)
        return response
    }

    @Action({ rawError: true })
    public async optionChange(payload: any) {
        const { option_group_uuid,option_uuid,event_identifier,type } = payload
        const data = payload
        const response = await Container.get(OptionsService).optionChange(option_group_uuid, option_uuid, event_identifier, type, data)  
        this.context.dispatch('auth/getParticipant', { event_identifier }, { root: true })
        this.context.dispatch('_participantUpdated', true)
        return response
    }

    @Action({ rawError: true })
    public _participantUpdated (payload: any) {
        this.context.commit('SET_PARTICIPANT_UPDATED', payload)
    }

    @Action({ rawError: true })
    public async optionDelete(payload: any) {
        const { option_group_uuid, option_uuid, event_identifier } = payload
        const response = await Container.get(OptionsService).optionDelete(option_group_uuid, option_uuid, event_identifier)
        const data = {
            event_identifier,
        }
        this.context.dispatch('auth/getParticipant', data, { root: true })
        return response
    }
}
